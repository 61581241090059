import React, { useRef, useEffect, forwardRef, useImperativeHandle } from 'react';


import './signature.scss';

export const Signature = forwardRef((props, ref) => {

    const fontsData = [
        
        {font:'Autography-DOLnW', size: 30, sizeList: 30},
        {font:'Signatures', size: 20, sizeList: 15},
        {font:'Signatures_2', size: 30, sizeList: 35},
        {font:'Thesignature', size: 35, sizeList: 35}
    ];

    const fontSelected = useRef(0);

    const signatureRemote = new Date().getTime();
    const canvas = useRef();
    let context;
    let isDraw = false;

    const remotearea = useRef();
    const createarea = useRef();


    //const config = {id:'teste', 'iceServers': [{ 'url': 'stun:stun.l.google.com:19302' }] };
    const config = {'iceServers': [{ 'url': 'stun:stun.l.google.com:19302' }] };


    let peer = useRef(null);
    let conn = useRef(null);


    const send = message =>{
        if(conn.current && conn.current.open){
            conn.current.send(message);
        }
    }

    const end = () =>{
        conn.current.close();
    }

    const setDraw = (e) => {
        isDraw = (/mousedown|touchstart/gi).test(e.type);
        if(isDraw){
            context = canvas.current.getContext('2d');
            const x  = Math.round((e.clientX || e.touches[0].clientX ) - canvas.current.getBoundingClientRect().left);
            const y  = Math.round((e.clientY  || e.touches[0].clientY) - canvas.current.getBoundingClientRect().top);
            context.moveTo(x, y);
            context.beginPath();
        }else{
            context.closePath();
        }
    }

    const clearCanvas = () => {
        context = canvas.current.getContext('2d');
        context.clearRect(0, 0, canvas.current.width, canvas.current.height);
    }

    const draw = (e) => {
        const x  = Math.round((e.clientX || e.touches[0].clientX ) - canvas.current.getBoundingClientRect().left);
        const y  = Math.round((e.clientY  || e.touches[0].clientY) - canvas.current.getBoundingClientRect().top);
        if(isDraw){
            context = canvas.current.getContext('2d');
            context.lineTo(x, y);
            context.stroke();
        }

    }

    const drawCreated = (_data) =>{
        clearCanvas();
        context = canvas.current.getContext('2d');
        context.font = `${_data.size || 30 }px ${_data.font}`;
        context.fillText(_data.text, 60, 100, 240);

        showCreate(null, false);
    }

    const showRemote = (_bl) => {
        if(_bl){
            remotearea.current.classList.add('active');
        }else{
            remotearea.current.classList.remove('active');
        }
    }

    const showCreate = (e, _bl) => {
        if(e){
            const box = createarea.current.querySelector('.main-frame-create-signature');
            box.style.display = 'absolute';
            box.style.bottom = `-35px`;
            box.style.left = `60%`;
            box.style.height = '459px';
            }
        if(_bl){
            createarea.current.classList.add('active');
        }else{
            createarea.current.classList.remove('active');
        }
    }

    function trimCanvas(c) {
        var ctx = c.getContext('2d'),
            copy = document.createElement('canvas').getContext('2d'),
            pixels = ctx.getImageData(0, 0, c.width, c.height),
            l = pixels.data.length,
            i,
            bound = {
                top: null,
                left: null,
                right: null,
                bottom: null
            },
            x, y;

        // Iterate over every pixel to find the highest
        // and where it ends on every axis ()
        for (i = 0; i < l; i += 4) {
            if (pixels.data[i + 3] !== 0) {
                x = (i / 4) % c.width;
                y = ~~((i / 4) / c.width);

                if (bound.top === null) {
                    bound.top = y;
                }

                if (bound.left === null) {
                    bound.left = x;
                } else if (x < bound.left) {
                    bound.left = x;
                }

                if (bound.right === null) {
                    bound.right = x;
                } else if (bound.right < x) {
                    bound.right = x;
                }

                if (bound.bottom === null) {
                    bound.bottom = y;
                } else if (bound.bottom < y) {
                    bound.bottom = y;
                }
            }
        }

        // Calculate the height and width of the content
        var trimHeight = bound.bottom - bound.top,
            trimWidth = bound.right - bound.left,
            trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

        copy.canvas.width = trimWidth;
        copy.canvas.height = trimHeight;
        copy.putImageData(trimmed, 0, 0);

        // Return trimmed canvas
        return copy.canvas;
    }

    const downloadTemp = (_canvas) => {
        var link = document.createElement('a');
        link.setAttribute('download', 'teste.png');
        _canvas.toBlob(item => {
            link.setAttribute('href', URL.createObjectURL(item));
            link.click();
        })
    }

    const saveSignature = () => {
        const temp  = trimCanvas(canvas.current);
        //downloadTemp(temp);
        return temp.toDataURL('image/png');
    }

    const isEmpty = () => {
        const context = canvas.current.getContext('2d');
        const data = context.getImageData(0, 0, canvas.current.width, canvas.current.height).data;
        return data.filter(color => ~~color === 0).length === data.length;
    }

    useImperativeHandle(ref, () => ({
        isEmpty: isEmpty,
        getImage: saveSignature
    }));

    return (
        <div className="signature-area">
            <div className="area-button">
                <button onClick={(e)=>{showCreate(e, true)}}>Criar assinatura digital</button>
                <button onClick={()=>{clearCanvas()}}>Limpar</button>
            </div>
            <div className="area">
                <canvas ref={canvas} disabled></canvas>
            </div>
            <div ref={remotearea} className={`dialog-remote`}>
                <div onClick={()=> showRemote(false)} className="close-button"></div>
                
            </div>
            <div ref={createarea} className="create-area">
                <div onClick={()=> showCreate(false)} className="close-button"></div>
                <div className="main-frame-create-signature">
                    <div className="list-fonts">
                        {
                            fontsData.map((item, id) =>{
                                return (<div className='signature-base-create'>
                                    <div>
                                    <input id={`create-signature-${id}`} name="create-signature" value={id} onClick={()=>{fontSelected.current = id; drawCreated({ text: `${localStorage.getItem("name")}`, ...fontsData[fontSelected.current]})}} type="radio" />
                                    <label style={{fontFamily: `${item.font }`, fontSize: `${item.sizeList}px` }} htmlFor={`create-signature-${id}`}>Exemplo Assinatura</label>
                                    </div>
                                </div>)
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
})