import * as urlsApi from "../../utils/contants/urlsApi";

import { buildErroHandleItems, buildPagination } from "../../helpers/sharedHelpers";
import { put, select, takeLatest } from "redux-saga/effects";

import API from "../../utils/API";
import { Types as MessageTypes } from "../ducks/messages";
import { Types } from "../ducks/manager";
import { history } from "../../utils/routes";
import * as routes from "../../utils/contants/urls";

function getProfileApi() {
  return API.get(
    `${urlsApi.REQUEST_PROFILES}`
  )
    .then(res => res.data)
    .catch(error => { throw error });
}


function* getProfileList() {
  try {
    const { lista } = yield getProfileApi();
    yield put({
      type: Types.PROFILES_SUCCESS,
      profiles: lista
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.PROFILES_FAIL, messages });
  }
}
function deleteUserApi(id) {
  return API.delete(`${urlsApi.DELETE_MANAGER}/${id}`)
    .then((res) => res.data)
    .catch((error) => { throw error })
}
function* deleteUser({ id }) {
  try {
    yield deleteUserApi(id)

    yield put({ type: Types.USER_DELETE_SUCCESS, id });
    yield getUsersList();
  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.USER_DELETE_FAIL, error });
  }
}

function getUsersApi() {
  return API.get(
    `${urlsApi.REQUEST_USERS}`
  )
    .then(res => res.data)
    .catch(error => { throw error });
}

function* getUsersList() {
  try {
    const usuarios = yield getUsersApi();
    yield put({
      type: Types.PROFILES_USERS_SUCCESS,
      users: usuarios
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.PROFILES_USERS_FAIL, messages });
  }
}


const updatePerfilUserApi = param =>

  API.put(urlsApi.REQUEST_PROFILES, param)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });


function* updatePerfilUser(payload) {
  try {
    const param = { IdPerfil: payload.payload.idNewPerfil, IdUser: payload.payload.userId };
    const usuarios = yield updatePerfilUserApi(param);
    yield put({
      type: Types.PROFILES_USER_UPDATE_SUCCESS,
      users: usuarios
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.PROFILES_USER_UPDATE_FAIL, messages });
  }
}



function getProfilePermissionsApi(id) {
  return API.get(`${urlsApi.REQUEST_PROFILEPERMISSIONS}?Id=${id}`)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}


function requestInformationRmSecaoList(body) {
  return API.put(`${urlsApi.GET_PROJETO_ADMIN}`, body)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

function* informationRmSecaoList(payload) {
  try {
    const body = { ...payload }
    const informationSecao = yield requestInformationRmSecaoList(body);
    yield put({
      type: Types.INFORMATION_RM_SECAO_LIST_SUCCESS,
      informationSecao
    });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({
      type: Types.INFORMATION_RM_SECAO_LIST_FAIL,
      messages: error,
    });
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
  }
}


function* getProfilePermissionsList(payload) {
  try {
    const profilepermissions = yield getProfilePermissionsApi(payload.payload);
    yield put({
      type: Types.PROFILES_PERMISSIONS_SUCCESS,
      profilepermissionsSelected: profilepermissions
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.PROFILES_PERMISSIONS_FAIL, messages });
  }
}




const editPermissionProfileApi = param =>

  API.put(urlsApi.EDIT_PERMISSIONS_PROFILES, param)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });


function* editPermissionProfile(payload) {
  try {
    const param = { id: payload.payload.id, permission: payload.payload.enabled };
    const profilepermissions = yield editPermissionProfileApi(param);
    yield put({
      type: Types.PROFILES_PERMISSIONS_SUCCESS,
      profilepermissionsSelected: profilepermissions
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.PROFILES_PERMISSIONS_FAIL, messages });
  }
}



const createUserApi = (user) =>

  API.post(urlsApi.CREATE_MANAGER, user)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });


function* createUser(payload) {
  try {
    const user = {
      nome: payload.payload.nome,
      email: payload.payload.email,
      pessoa_Id: payload.payload.pessoa_Id,
      perfilAcesso_Id: payload.payload.perfilAcesso_Id,
      podeEditarPerfil: payload.payload.podeEditarPerfil ? 1 : 0,
      podeAcessarPlug: payload.payload.podeAcessarPlug ? 1 : 0,
      podeAcessarTalentos: payload.payload.podeAcessarTalentos ? 1 : 0,
      projetoId: payload.payload.projetoId,
      colidagaId: payload.payload.colidagaId,
    };
    yield createUserApi(user);
    yield put({
      type: Types.USERS_SUCCESS,
    });
    const messages = [{ type: "info", message: "Sucesso ao Criar um Administrador." }];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });
    history.push(`${routes.USERS}`);
  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.PROFILES_CREATE_FAIL, messages });
  }
}


const createProfileApi = nome =>

  API.post(urlsApi.CREATE_PROFILES, nome)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });


function* createProfile(payload) {
  try {
    const nome = { nome: payload.payload.name };
    const { lista } = yield createProfileApi(nome);

    yield put({
      type: Types.PROFILES_CREATE_SUCCESS,
      profiles: lista
    })
    const messages = [{ type: "info", message: "Sucesso ao Criar um Profile." }]
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });
  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.PROFILES_CREATE_FAIL, messages });
  }
}




const enableStageProfileApi = param =>

  API.put(urlsApi.EDIT_ACCESS_STAGE_PROFILES, param)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });

const enablePlugProfileApi = param =>
  API.put(urlsApi.CHANGE_ACESS_PLUG_MANAGER, param)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });

function* enablePlugProfile(payload) {
  try {
    const param = { id: payload.payload.id, PodeAcessarPlug: payload.payload.enabled };
    yield enablePlugProfileApi(param);
    yield put({
      type: Types.PROFILES_ENABLE_PLUG_SUCCESS,
      users: yield getUsersList()
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.PROFILES_ENABLE_PLUG_FAIL, messages });
  }
}

const enableTalentosProfileApi = param =>
  API.put(urlsApi.CHANGE_ACESS_TALENTOS_MANAGER, param)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });

function* enableTalentosProfile(payload) {
  try {
    const param = { id: payload.payload.id, PodeAcessarTalentos: payload.payload.enabled };
    yield enableTalentosProfileApi(param);
    yield put({
      type: Types.PROFILES_ENABLE_TALENTOS_SUCCESS,
      users: yield getUsersList()
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.PROFILES_ENABLE_TALENTOS_FAIL, messages });
  }
}

const enableManagerProfileApi = param =>
  API.put(urlsApi.CHANGE_ACESS_USER_MANAGER, param)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });

function* enableManagerProfile(payload) {
  try {
    const param = { id: payload.payload.id, PodeEditarPerfil: payload.payload.enabled };
    yield enableManagerProfileApi(param);
    yield put({
      type: Types.PROFILES_ENABLE_MANAGER_SUCCESS,
      users: yield getUsersList()
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.PROFILES_ENABLE_MANAGER_FAIL, messages });
  }
}

function* enableStageProfile(payload) {
  try {
    const param = { id: payload.payload.id, PodeEditarEtapas: payload.payload.enabled };
    const profilepermissions = yield enableStageProfileApi(param);
    yield put({
      type: Types.PROFILES_ENABLE_STAGE_SUCCESS,
      profilepermissionsSelected: profilepermissions
    });
  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.PROFILES_ENABLE_STAGE_FAIL, messages });
  }
}

const changeUserApi = (param) =>
  API.put(urlsApi.CHANGE_USER, param)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });

function* changeUser(payload) {
  try {
    const param = {
      user_Id: payload.payload.user_Id,
      perfilAcesso_Id: payload.payload.perfilAcesso_Id,
      locaisDeTrabalho: payload.payload.locaisDeTrabalho,
      projetoId: payload.payload.projetoId,
      coligadaId: payload.payload.coligadaId,
    };
    yield changeUserApi(param);
    yield getUsersId({ payload: payload.payload.user_Id });
    yield put({
      type: Types.CHANGE_USER_SUCCESS,
    });
    const messages = [{ type: "info", message: "Sucesso ao Editar o Administrador." }];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });
  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.CHANGE_USER_FAIL, messages });
  }
}

function requestUsersId(id) {
  return API.get(`${urlsApi.REQUEST_USERS_ID}/${id}`)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}
function* getUsersId({ payload }) {
  try {
    const lista = yield requestUsersId(payload);
    yield put({
      type: Types.REQUEST_USERS_ID_SUCCESS,
      currentUser: lista,
    });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.REQUEST_USERS_ID_FAIL, error });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.PROFILES_REQUEST, getProfileList);
  yield takeLatest(Types.PROFILES_USERS_REQUEST, getUsersList);
  yield takeLatest(Types.USERS_REQUEST, createUser);
  yield takeLatest(Types.PROFILES_USER_UPDATE_REQUEST, updatePerfilUser);
  yield takeLatest(Types.PROFILES_PERMISSIONS_REQUEST, getProfilePermissionsList);
  yield takeLatest(Types.PROFILES_EDIT_PERMISSIONS_REQUEST, editPermissionProfile);
  yield takeLatest(Types.PROFILES_CREATE_REQUEST, createProfile);
  yield takeLatest(Types.PROFILES_ENABLE_STAGE_REQUEST, enableStageProfile);
  yield takeLatest(Types.PROFILES_ENABLE_PLUG_REQUEST, enablePlugProfile);
  yield takeLatest(Types.PROFILES_ENABLE_TALENTOS_REQUEST, enableTalentosProfile);
  yield takeLatest(Types.USER_DELETE_REQUEST, deleteUser);
  yield takeLatest(Types.PROFILES_ENABLE_MANAGER_REQUEST, enableManagerProfile);

  yield takeLatest(Types.CHANGE_USER_REQUEST, changeUser);
  yield takeLatest(Types.REQUEST_USERS_ID, getUsersId);
  yield takeLatest(Types.INFORMATION_RM_SECAO_LIST_REQUEST, informationRmSecaoList);
}
