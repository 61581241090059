import theme from "../../../../../components/shared/Theme";
import {
  ActionItem,
  Actions,
  Label,
  LabelAction,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow,
} from "components/shared/CustomComponentsStyle";
import {
  TextField,
  SelectInput,
} from "components/shared/CustomInputs";
import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { view } from "assets/icons/icons";
import { Body } from "./ListDoubtsCandidatesStyle";
import BreadCrumb from "components/Layout/breadcrumb";

import {
  Button,
  Form,
  Modal,
  InputGroup,
} from "react-bootstrap";

import {
  MultSelectCreatable,
  MultSelect,
  ButtonSecondary,
  ButtonDefault,
} from "components/shared/CustomInputs";

import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Load from "components/Layout/Load";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Creators as doubtsAction } from "store/ducks/doubts";
import { Creators as candidatesAction } from "store/ducks/candidates";
import Pagination from "components/Pagination";
import { Creators as messageAction } from "store/ducks/messages";

import {
  FluxoValeTransporte,
  FluxoValeRefeicao,
  FluxoPlanoDeSaude,
  FluxoContraCheque,
  FluxoFerias,
  FluxoDemissao,
  FluxoPonto,
  FluxoAdmissao,
  StatusSolicitacaoCandidato,
  StatusSla,
  TiposPrimitivoDeSolicitacao,
} from "../../../../../utils/contants/enums";

const RowColorStage = (status) => {
  let color;
  switch (status) {
    case 0:
      color = "	#c90808";
      break;
    case 1:
      color = "	#F0E68C";
      break;
    case 2:
      color = "	#32CD32";
      break;
    case 3:
      color = "#A020F0";
      break;
    default:
      color = "#FFFFE0";
      break;
  }

  return {
    color,
    height: "18px",
    width: "18px",
    borderRadius: "50%",
    float: "left",
    display: "inline-block",
    backgroundColor: color,
  };
};

const ListDoubtsCandidates = ({
  doubtsListRequest,
  duvidas,
  candidates,
  isLoading,
  pagination,
  location,
  messagePublication,
  doubtsAnswersRequest,
  downloadDocument,
  doubtsChangeStatus,
  coligadaCandidato,
  informacoesCandidatoColigadaRequest,
  doubtsCreateManagerRequest,
}) => {
  const [clearFilter, setClearFilter] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [formErros, setFormErros] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [formValidators, setFormValidators] = useState(() => []);
  const [formValues, setFormValues] = useState({
    cpfs: [],
    idVagas: [],
    idLocations: [],
    coligadaCandidato: [],
    dataAdmissao: "",
    nomeFuncionario: "",
    vaga_Id: "",
    motivo: "",
    assunto: "",
    descricao: "",
    statusDuvida: "",
    candidato_Id: "",
    respostaAdm: "",
    tituloalert: "",
    idStatus: [],
    idMotivoList: [],
    id: "",
    duracao: "",
    idStatusSla: "",
    Nome: "",
    motivoContato: 0,
    tipoPrimitivoDeSolicitacao: 0,
    fluxoContato: "",
    selectedOption: "",
  });
  const formValuesRef = useRef([formValues]);
  const [selectedFile, setSelectedFile] = useState(null);
  const hiddenFileInput = useRef(null);
  const [erroMessage, setErroMessage] = useState({ message: "" });
  const [contextDuvidas, setContextDuvidas] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [showNewDoubtModal, setShowNewDoubtModal] = useState(false);

  const handleShow = (duvida) => {
    formValuesRef.current = duvida;
    setShow(true);
  };

  const AplicaFiltro = (form, firstPage) => {
    localStorage.setItem("filter_ListDoubtsCandidates", JSON.stringify(form));
    doubtsListRequest({
      cpfList: form.cpfs,
      idStatusList: form.idStatus,
      idMotivoList: form.idMotivoList,
      idLocalTrablaho: form.coligadaCandidato,
      slaStatus: form.idStatusSla,
      duracao: form.duracao,
      firstPage,
      NumeroPagina: form.NumeroPagina,
      Nome: form.Nome,
    });
    setFormValues(form);
  };

  const handleChangeTime = (e, fieldName) => {
    let rawValue = e.target.value;

    // Remover caracteres não numéricos
    rawValue = rawValue.replace(/[^\d]/g, "");

    // Limitar a 5 dígitos
    rawValue = rawValue.slice(0, 5);

    // Formatar dinamicamente como "0d 00h 00m" com limites de horas e minutos
    let formattedValue = "";
    if (rawValue.length === 1) {
      formattedValue = `${rawValue}d `;
    } else if (rawValue.length === 2) {
      formattedValue = `${rawValue[0]}d ${rawValue[1]}h `;
    } else if (rawValue.length === 3) {
      const hours = Math.min(parseInt(rawValue.slice(1, 3), 10), 24)
        .toString()
        .padStart(2, "0");
      formattedValue = `${rawValue[0]}d ${hours}h `;
    } else if (rawValue.length === 4) {
      const hours = Math.min(parseInt(rawValue.slice(1, 3), 10), 24)
        .toString()
        .padStart(2, "0");
      const minutes = Math.min(parseInt(rawValue[3], 10), 59).toString();
      formattedValue = `${rawValue[0]}d ${hours}h ${minutes}m`;
    } else if (rawValue.length === 5) {
      const hours = Math.min(parseInt(rawValue.slice(1, 3), 10), 24)
        .toString()
        .padStart(2, "0");
      const minutes = Math.min(parseInt(rawValue.slice(3, 5), 10), 59)
        .toString()
        .padStart(2, "0");
      formattedValue = `${rawValue[0]}d ${hours}h ${minutes}m`;
    }

    setFormValues((prevState) => ({
      ...prevState,
      [fieldName]: formattedValue,
    }));
  };

  const Filtrar = () => {
    setClearFilter(false);
    AplicaFiltro(formValues, true);
  };

  const TrataMultiInsertColigada = (Insert) => {
    setErroMessage({ message: "" });
    let result = [];

    if (Insert.length > 0) {
      Insert.forEach((item) => {
        result.push(item.value);
      });
    }
    return result;
  };

  const getMotivoEtapaValues = () => {
    if (formValues.idMotivoList) {
      return TiposPrimitivoDeSolicitacao.filter(function (item) {
        return formValues.idMotivoList.indexOf(item.value) !== -1;
      });
    }

    return [];
  };

  const getStatusEtapaValues = () => {
    if (formValues.idStatus) {
      return StatusSolicitacaoCandidato.filter(function (item) {
        return formValues.idStatus.indexOf(item.value) !== -1;
      });
    }

    return [];
  };

  const LimparFiltro = () => {
    setClearFilter(true);
    localStorage.removeItem("filter_ListDoubtsCandidates");
    setFormValues({});
    AplicaFiltro({}, true);
  };

  const TrataMultiInsert = (Insert) => {
    setErroMessage({ message: "" });
    let messages = [];
    let result = [];
    Insert.map((item) => {
      let conc = item.value.split(/[\s,]+/);
      conc.map((filtItem) => {
        if (filtItem.length === 11 || filtItem.length === 14) {
          result.push(filtItem);
        } else {
          messages.push({
            message: `motivo ${filtItem} com menos de 11 digitos. Não será considerado no filtro.`,
            type: "warning",
          });
        }
      });
    });
    messagePublication(messages);
    return result;
  };

  const requestPage = (filter) => {
    doubtsListRequest({ ...formValues, NumeroPagina: filter.NumeroPagina });
  };

  const renderTableItems = (duvidasList) =>
    duvidasList.map((duvida) => (
      <TableRow AlertaGeral={duvida.statusDuvida === 3}>
        <TableColumn>
          <span class="dot" style={RowColorStage(duvida.statusDuvida)} />
        </TableColumn>
        <TableColumn ta="center">
          <Label>{duvida.id}</Label>
        </TableColumn>
        <TableColumn ta="center">
          {duvida.statusDuvida != 3 ? (
            <Label>
              {
                StatusSolicitacaoCandidato.find(
                  (motivo) => motivo.value == duvida.statusDuvida
                ).label
              }
            </Label>
          ) : (
            "ALERTA"
          )}
        </TableColumn>
        <TableColumn ta="left">
          <Label>{duvida.destinatario}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{duvida.dataCriacao}</Label>
        </TableColumn>
        <TableColumn ta="center">
          <Label>{duvida.slaDuracao}</Label>
        </TableColumn>
        <TableColumn ta="center">
          <Label>{duvida.slaStatus}</Label>
        </TableColumn>
        <TableColumn ta="center">
          <Label>{duvida.localTrabalho}</Label>
        </TableColumn>
        <TableColumn ta="center">
          <Label>{duvida.motivoCompleto}</Label>
        </TableColumn>
        <TableColumn ta="right" action>
          <Link onClick={() => handleShow(duvida)}>
            <Actions flexPosition="flex-start">
              <ActionItem>
                {view()}
                <LabelAction>
                  <FormattedMessage id="sharedItems.view" />
                </LabelAction>
              </ActionItem>
            </Actions>
          </Link>
        </TableColumn>
      </TableRow>
    ));

  const renderCandidatesTable = () => {
    return (
      <>
        <Table>
          <TableBody>
            <TableRow noBorder>
              <TableHead>
                <LabelHeader>{""}</LabelHeader>
              </TableHead>
              <TableHead ta="center">
                <LabelHeader>
                  <FormattedMessage id="duvidasCandidateList.id" />
                </LabelHeader>
              </TableHead>
              <TableHead>
                <LabelHeader>
                  <FormattedMessage id="duvidasCandidateList.status" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="duvidasCandidateList.nomeCandidato" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="duvidasCandidateList.dataDoChamado" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="center">
                <LabelHeader>
                  <FormattedMessage id="duvidasCandidateList.duracao" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="center">
                <LabelHeader>
                  <FormattedMessage id="duvidasCandidateList.slaStatus" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="center">
                <LabelHeader>
                  <FormattedMessage id="duvidasCandidateList.localTrabalho" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="center">
                <LabelHeader>
                  <FormattedMessage id="duvidasCandidateList.motivoDoContato" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>{""}</LabelHeader>
              </TableHead>
            </TableRow>
            {renderTableItems(duvidas)}
          </TableBody>
        </Table>
        <Pagination getItemsPage={requestPage} pageSize={20} {...pagination} />
      </>
    );
  };

  const handleSendMessage = (event) => {
    event.preventDefault();

    if (
      (formValues.mensagem == null || formValues.mensagem == "") &&
      selectedFile == null
    ) {
      window.alert("Mensagem/arquivo não pode ser vazio");
    } else {
      const payload = {
        respostaAdm: formValues.mensagem,
        solicitante: formValuesRef.current.administrador_Solicitante_Id,
        id: formValuesRef.current.id,
        arquivo: selectedFile,
      };

      doubtsAnswersRequest(payload);
      setFormValues({ mensagem: "" });
      setShow(false);
      setSelectedFile(null);
    }
  };

  const handleInputChange = (e) => {
    setFormValues({
      ...formValues,
      mensagem: e.target.value,
    });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const changeSolicitationStatus = (event) => {
    event.preventDefault();

    const payload = {
      id: formValuesRef.current.id,
    };

    doubtsChangeStatus(payload);
    setShow(false);
  };

  const handleMotivoChange = (selectedOption) => {
    setFormValues({
      ...formValues,
      motivoContato: selectedOption.value,
      tipoPrimitivoDeSolicitacao: selectedOption.value,
      fluxoContato: "",
      selectedOption: selectedOption,
    });
  };

  const handleFluxoChange = (selectedOption) => {
    setFormValues((prevState) => ({
      ...prevState,
      fluxoContato: selectedOption,
    }));
  };

  const handleNewDoubtSubmit = (event) => {
    event.preventDefault();

    if (formValues.mensagem == null || formValues.mensagem == "") {
      window.alert("Mensagem não pode ser vazio");
      return;
    }

    if (formValues.tituloalert == null || formValues.tituloalert == "") {
      window.alert("Título não pode ser vazio");
      return;
    } else {
      doubtsCreateManagerRequest({
        isAlert: true,
        mensagem: formValues.mensagem,
        alertaTitulo: formValues.tituloalert,
        arquivo: selectedFile,
      });
      setFormValues({});
      setSelectedFile(null);
      setShowNewDoubtModal(false);
    }
  };

  useEffect(() => {
    if (coligadaCandidato.length == 0) {
      informacoesCandidatoColigadaRequest();
    }
  }, []);

  useEffect(() => {
    if (candidates.candidatos != undefined && candidates.candidatos.length) {
      const dupContextList = candidates.candidatos.map((candidate) => {
        return { ...candidate, selected: false };
      });

      setContextDuvidas(dupContextList);
    }

    let filter_storaged = JSON.parse(
      localStorage.getItem("filter_ListDoubtsCandidates")
    );
    if (filter_storaged != null && !clearFilter) {
      filter_storaged.quantidadeItens = candidates.candidatos
        ? candidates.candidatos.length
        : 20;
      localStorage.setItem(
        "filter_ListDoubtsCandidates",
        JSON.stringify(filter_storaged)
      );
      setFormValues(filter_storaged);
    } else {
      setFormValues({
        cpfs: [],
        idVagasList: [],
        idVagas: [],
        idLocations: [],
        idStatus: [],
        idMotivoList: [],
        dateInitActivity: null,
      });
    }

    if (candidates.candidatos == undefined) {
      AplicaFiltro(formValues, true);
    }
  }, [candidates]);

  if (isLoading) return <Load isLoading={isLoading} />;
  return (
    <React.Fragment>
      <BreadCrumb
        location={location}
        title={<FormattedMessage id="sharedItems.selectSolicitation" />}
      />
      <Body>
        <Row style={{ marginBottom: "10px" }}>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md={2} style={{ marginTop: "15px" }}>
              <ButtonSecondary
                onClick={() => Filtrar()}
                name={<FormattedMessage id="sharedItems.filter" />}
              />
            </Col>
            <Col md={2} style={{ marginTop: "15px" }}>
              <ButtonSecondary
                onClick={() => LimparFiltro()}
                name={<FormattedMessage id="sharedItems.clearFilter" />}
              />
            </Col>
            <Col md={2} style={{ marginTop: "15px" }}>
              <ButtonSecondary
                onClick={() => setShowNewDoubtModal(true)}
                name={<FormattedMessage id="sharedItems.createAlert" />}
              />
            </Col>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col style={{ display: "flex", justifyContent: "flex-start" }}>
            <Col md={3}>
              <TextField
                name="Nome"
                value={formValues.Nome || ""}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    Nome: e.target.value,
                  })
                }
                label={
                  <FormattedMessage id="documentsCandidateList.nomeCandidato" />
                }
                setFormErros={setFormErrors}
                formValidators={setFormValidators}
                touched={submitted}
                formErr={formErrors}
              />
            </Col>
            <Col md={3}>
              <MultSelect
                name={<FormattedMessage id="contracts.motivoDoContato" />}
                options={TiposPrimitivoDeSolicitacao}
                label={<FormattedMessage id="contracts.motivoDoContato" />}
                isMulti
                value={getMotivoEtapaValues()}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    idMotivoList: e
                      ? e.map((k) => {
                          return k.value;
                        })
                      : [],
                  })
                }
              />
            </Col>
            <Col md={3}>
              <MultSelect
                isMulti
                name={
                  <FormattedMessage id="documentsCandidateList.filterByGroup" />
                }
                label={
                  <FormattedMessage id="documentsCandidateList.filterByGroup" />
                }
                value={
                  formValues.coligadaCandidato && coligadaCandidato
                    ? formValues.coligadaCandidato
                        .map((id) =>
                          coligadaCandidato.find((e) => e.id === id)
                            ? {
                                label: coligadaCandidato.find(
                                  (e) => e.id === id
                                ).nome,
                                value: id,
                              }
                            : null
                        )
                        .filter(Boolean)
                    : []
                }
                options={
                  coligadaCandidato
                    ? coligadaCandidato.map((e) => ({
                        label: e.nome,
                        value: e.id,
                      }))
                    : []
                }
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    coligadaCandidato: e ? TrataMultiInsertColigada(e) : [],
                  });
                }}
              />
            </Col>
            <Col md={2}>
              <MultSelect
                name={<FormattedMessage id="contracts.status" />}
                options={StatusSolicitacaoCandidato}
                label={<FormattedMessage id="contracts.status" />}
                isMulti
                value={getStatusEtapaValues()}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    idStatus: e
                      ? e.map((k) => {
                          return k.value;
                        })
                      : [],
                  })
                }
              />
            </Col>
          </Col>
        </Row>
        <Row>
          <Col style={{ display: "flex", justifyContent: "flex-start" }}>
            <Col md={2}>
              <TextField
                name="duracao"
                placeholder="0d 00h 00m"
                value={formValues.duracao || ""}
                onChange={(e) => handleChangeTime(e, "duracao")}
                label={
                  <FormattedMessage id="documentsCandidateList.duration" />
                }
                setFormErros={setFormErrors}
                formValidators={setFormValidators}
                disabled={!!formValues.sla}
                touched={submitted}
                formErr={formErrors}
              />
            </Col>
            <Col md={2}>
              <SelectInput
                options={StatusSla}
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    idStatusSla: e.value,
                  });
                }}
                value={
                  formValues.idStatusSla !== ""
                    ? StatusSla.find(
                        (e) => e.value === Number(formValues.idStatusSla)
                      )
                    : null
                }
                label={<FormattedMessage id="contracts.statusSla" />}
              />
            </Col>
            <Col md={2}>
              <MultSelectCreatable
                key={5}
                name={<FormattedMessage id="documentsCandidateList.document" />}
                label={
                  <FormattedMessage id="documentsCandidateList.document" />
                }
                isMulti
                options={formValues.cpfs}
                value={
                  formValues.cpfs
                    ? formValues.cpfs.map((e) => ({
                        label: e,
                        value: e,
                      }))
                    : []
                }
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    cpfs: e ? TrataMultiInsert(e) : [],
                  })
                }
              />
            </Col>
          </Col>
        </Row>
        {duvidas && renderCandidatesTable(duvidas)}
      </Body>

      <Modal
        show={showNewDoubtModal}
        onHide={() => setShowNewDoubtModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Criar Alerta Geral</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="mensagem" className="mt-3">
              <Form.Label>Titulo</Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                placeholder="Titulo"
                value={formValues.tituloalert}
                maxLength={250}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    tituloalert: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group controlId="mensagem" className="mt-3">
              <Form.Label>Mensagem</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Digite sua mensagem aqui"
                value={formValues.mensagem}
                maxLength={500}
                onChange={(e) =>
                  setFormValues({ ...formValues, mensagem: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="doubtFile" className="mt-3">
              <section
                className="sendDocument"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <ButtonDefault
                  mt={"30px"}
                  disabled={isLoading}
                  isLoading={isLoading}
                  onClick={handleNewDoubtSubmit}
                  name={"Enviar"}
                  style={{ width: "30%" }}
                />

                <section
                  className="conteiner_envio_documento"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "10px",
                  }}
                >
                  <Form.Label>Anexar Arquivo</Form.Label>

                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => hiddenFileInput.current.click()}
                    className="mt-2"
                  >
                    {selectedFile ? selectedFile.name : "Escolher Arquivo"}
                  </Button>
                </section>
              </section>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={() => handleClose()} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Chat da Solicitação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Exibe o motivo e o assunto da dúvida */}
          <div
            style={{
              marginBottom: "10px",
              padding: "10px",
              borderBottom: "1px solid #ddd",
            }}
          >
            {formValuesRef.current.statusDuvida === 3 ? (
              <p>
                <strong>Motivo: </strong>

                {formValuesRef.current.descricao || "N/A"}
              </p>
            ) : (
              <>
                <p>
                  <strong>Motivo: </strong>

                  {formValuesRef.current.motivo || "N/A"}
                </p>
                <p>
                  <strong>Assunto: </strong>
                  {formValuesRef.current.descricao || "N/A"}
                </p>
              </>
            )}
          </div>

          {/* Caixa de mensagens */}
          <div
            className="chat-box"
            style={{
              maxHeight: "350px",
              overflowY: "auto",
              border: "1px solid #ddd",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            {formValuesRef.current.respostaAdm?.length > 0 ? (
              formValuesRef.current.respostaAdm.map((message, index) => (
                <div
                  key={index}
                  style={{
                    textAlign: message.perfilRemetente === 1 ? "left" : "right",
                    lineHeight: "2rem",
                    marginBottom: "3px",
                    borderRadius: "5px",
                    background: "#F5F5F5",
                    padding: "7px",
                  }}
                >
                  <strong
                    style={{
                      color:
                        message.perfilRemetente === 1
                          ? theme.colors.colorLabelDefault
                          : theme.colors.darkGray,
                      fontSize: "14px",
                    }}
                  >
                    {message.perfilRemetente === 1
                      ? message.remetente + " "
                      : message.remetente}
                  </strong>
                  <br />

                  {message.texto}

                  {message.caminhoArquivo && (
                    <div>
                      <div
                        onClick={() => {
                          downloadDocument(message);
                        }}
                        style={{
                          fontWeight: "600",
                          color: theme.colors.defaultColor,
                          cursor: "pointer",
                        }}
                      >
                        {message.nomeArquivo}
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>Nenhuma mensagem encontrada.</p>
            )}
          </div>

          {/* Campo para nova mensagem e upload de arquivos */}
          {formValuesRef.current.statusDuvida != 2 &&
          formValuesRef.current.statusDuvida != 3 ? (
            <Form>
              <Form.Group controlId="newMessage">
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Digite sua mensagem..."
                    value={formValues.mensagem || ""}
                    onChange={handleInputChange}
                    required
                    style={{ height: "50px" }}
                  />
                </InputGroup>

                <br />

                <section
                  className="botoesGerenciarSolicitacao"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "50px",
                  }}
                >
                  <Button
                    type="button"
                    variant="primary"
                    className="mt-3"
                    onClick={handleSendMessage}
                  >
                    {"Enviar"}
                  </Button>

                  <section
                    className="sendDocument"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <Form.Label>Anexar Arquivo</Form.Label>

                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => hiddenFileInput.current.click()}
                      className="mt-2"
                    >
                      {selectedFile ? selectedFile.name : "Escolher Arquivo"}
                    </Button>
                  </section>
                </section>
              </Form.Group>
            </Form>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          {formValuesRef.current.statusDuvida != 3 && (
            <Button
              variant="secondary"
              onClick={(e) => changeSolicitationStatus(e)}
              disabled={formValuesRef.current.statusDuvida == 2}
            >
              Encerrar solicitação
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doubtsListRequest: (filter) =>
    dispatch(doubtsAction.doubtsListRequest(filter)),

  doubtsAnswersRequest: (filter) =>
    dispatch(doubtsAction.doubtsAnswersRequest(filter)),

  messagePublication: (messages) =>
    dispatch(messageAction.messagePublication(messages)),

  downloadDocument: (messages) =>
    dispatch(doubtsAction.downloadDocumentsSolicitationFilesRequest(messages)),

  informacoesCandidatoColigadaRequest: () =>
    dispatch(candidatesAction.informacoesCandidatoColigadaPerfilRequest()),

  doubtsChangeStatus: (payload) =>
    dispatch(doubtsAction.doubtsSolicitationRequest(payload)),

  doubtsCreateManagerRequest: (doubts) =>
    dispatch(doubtsAction.doubtsCreateManager(doubts)),
});

const mapStateToProps = (state) => ({
  isLoading: state.doubts.isLoading,
  duvidas: state.doubts.duvidas,
  pagination: state.doubts.pagination,
  coligadaCandidato: state.candidates.coligadaCandidato,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListDoubtsCandidates);

ListDoubtsCandidates.propTypes = {
  candidates: PropTypes.func,
  hasMore: PropTypes.any,
  quantidadeItens: PropTypes.any,
};

ListDoubtsCandidates.defaultProps = {
  candidates: () => {},
};
