import { BoxBreadCrumb, BoxBreadItems, LabelBread } from "./BreadCrumbStyle";

import { LabelH1 } from "../../shared/CustomComponents";
import { Link } from "react-router-dom";
import React from "react";
import { buildRouters } from "../../../helpers/sharedHelpers";

import * as urls from "../../../utils/contants/urls";

export default function BreadCrumb({
  location,
  preTitle,
  title,
  isAssociating
}) {

  const routersdata = [];
  const pathname = location ? location.pathname.replace(/^\//gi,'').split('/') : [];

  
  pathname.map((item, id) => {
    const isExist = Object.values(urls).filter((_url) => {
      const el = `/${pathname.slice(0, id +1).join('/')}`;
      return  el.toString() === _url.toString()
    })[0];

    if(isExist){
      routersdata[id] = item;
    }else if(id > 1){
      //&& !(/editar/gi).test(item)
      if((/editar/gi).test(item)){
        routersdata.push(`editar`)
      }else{
        routersdata[routersdata.length - 1] += `/${item}`;
      }
    }
    return item;
  })

  const routers = routersdata.filter(item => item);

  const buildLink = (route, index) => {
    let url = ""; 
    if (isAssociating) {
      url += `/detalhes/${routers.pop()}`;
    } else {
      for (let i = 0; i < index; i++) {
        url += `/${routers[i]}`;
      }
    }
    url += `/${route}`;
    return url;
  };

  const shouldLink = route => {
    return route !== "Editar";
  };
  return (
    <BoxBreadCrumb>
      <BoxBreadItems>
        {!isAssociating ? (
          routers.map((route, index) => (
            <React.Fragment>
              <LabelBread>
                {shouldLink(route) ? (
                  <Link to={buildLink(route, index)}>{(`${/editar/gi.test(route) ? `${routers[index - 1]} ` : ''}${route}`).replace(/\//,' ')}</Link>
                ) : (
                  route.replace(/\//,' ')
                )}
              </LabelBread>
              <LabelBread>{index + 1 < routers.length && ">"}</LabelBread>
            </React.Fragment>
          ))
        ) : (
          <React.Fragment>
            <LabelBread>
              <Link to="/talentos/vagas">Vagas</Link>
            </LabelBread>
            <LabelBread>
              <div onClick={() => window.history.back()}>Associar</div>
            </LabelBread>
          </React.Fragment>
        )}
      </BoxBreadItems>
      <LabelH1>
        {preTitle && preTitle}
        {preTitle && `: `}
        {title}
      </LabelH1>
    </BoxBreadCrumb>
  );
}
