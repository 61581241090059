import { StatusStages } from "../locale";
import moment from "moment";
import { months } from "../locale";
import theme from "../components/shared/Theme";
import { number, stageStatus } from "./validations";


const STATUS_WAITING = stageStatus[0].id;
const STATUS_ANALYZING = stageStatus[1].id;
const STATUS_DECLINE = stageStatus[2].id;
const STATUS_APPROVED = stageStatus[3].id;
const STATUS_CANCELED = stageStatus[4].id;
const STATUS_NOT_INITIALIZE = stageStatus[5].id;
const STATUS_PENDING = stageStatus[6].id;
const STATUS_CONCLUDED = stageStatus[7].id;

export const SliceText = (text, viewSize) => {
  if (text && text.length > viewSize) return `${text.slice(0, viewSize)}...`;
  if (text && text.length < viewSize) return `${text.slice(0, viewSize)}`;
};
export const isValidEmitionDate = value => {
  const actualDate = new Date();
  const emition = buildDate(value);
  if (emition) {
    if (emition < actualDate) return true;
  }
  return false;
};
export const buildDate = date => {
  if (date instanceof Date) {
    return date;
  }
  if (date) {
    const onlyDate = date.split(" ");
    const dateItems = onlyDate.length
      ? onlyDate[0].split("/")
      : date.split("/");
    const day = dateItems[0];
    const month = dateItems[1];
    const year = dateItems[2];
    return new Date(`${year}-${month}-${day}T00:00:00`);
  }
  return new Date();
};

export const validateStringDate = date => {
  if (date instanceof Date) {
    return false;
  }
  if (date) {
    const onlyDate = date.split(" ");
    const dateItems = onlyDate.length
      ? onlyDate[0].split("/")
      : date.split("/");
    const day = 1 <= parseInt(dateItems[0]) && parseInt(dateItems[0]) <= 31;
    const month = 1 <= parseInt(dateItems[1]) && parseInt(dateItems[1]) <= 12;
    const year = dateItems[2].length == 4 && isNaN(parseInt(dateItems[2]));
    return day && month && year;
  }
  return false;
};

export const isValidDate = date => {
  return moment(date, 'DD/MM/YYYY').isValid();
};

export const buildDateString = date => {
  if (date instanceof Date) {
    const day = date.getDate();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }
  return null;
};

export const buildDateStringWithHour = (date, hour) => {
  if (date) {
    const onlyDate = date.split(" ");
    const dateItems = onlyDate.length
      ? onlyDate[0].split("/")
      : date.split("/");
    const day = dateItems[0];
    const month = dateItems[1];
    const year = dateItems[2];

    const hoursAndMinutes = hour.split(":");
    const hours = hoursAndMinutes[0].padStart(2, 0);
    const minutes = hoursAndMinutes[1].padStart(2, 0);

    return `${year}-${month}-${day}T${hours}:${minutes}:00`;
  }
  return null;
};

export const calcAge = birth => {
  if (birth) {
    const date = moment().diff(birth, "years");
    return `${date} anos`;
  }
  return null;
};

export const convertTimeToMinutes = time => {
  const [hour, minuts, seconds] = time.split(":");
  return (
    parseInt(hour, 10) * 3600 +
    parseInt(minuts, 10) * 60 +
    parseInt(seconds, 10)
  );
};


export const buildPagination = (
  paginacao,
  info,
  list,
  stateList,
  itemsPorPagina = 10
) => {
  const pagination = createPaginationObject(paginacao, info, list, stateList);

  return {
    sizeItems: (pagination.quantidade / itemsPorPagina) * itemsPorPagina,
    currentPage: pagination.page,
    sizeList: list?.length,
    filter: pagination.campo,
    filterValue: pagination.value,
    pageSize: itemsPorPagina,
  };
};

export const getLast3MonthsClosedFormat = () => {
  return getFormatMonthArray(last3MonthsClosed());
}

export const getMonthClosedRobbysonFormat = () => {
  return getFormatMonthArray(monthClosed());
}
const monthClosed = () => {
  let date = new Date();
  let month = moment(date).month();

  return [month - 1];
}

const last3MonthsClosed = () => {
  let date = new Date();
  let month = moment(date).month() + 1;
  let months = [];

  for (let i = 0; i < 3; i++) {

    if (month - 1 == 0) {
      month = 12;
      months.push(month);
    }
    else {
      month = month - 1;
      months.push(month);
    }
  }

  return months;
}

export const buildTimeFromSeconds = timer => {
  const hour = timer / 3600;
  const minuts = (timer - (3600 * parseInt(hour, 10))) / 60;
  const seconds = timer - ((60 * parseInt(minuts, 10)) + (3600 * parseInt(hour, 10)));
  return `${hour >= 10 ? "" : "0"}${parseInt(hour, 10)}:${minuts >= 10 ? "" : "0"}${parseInt(minuts, 10)}:${seconds >= 10 ? "" : "0"}${parseInt(seconds, 10)}`;
};

export const buildCode = (date, prefix, id) => {
  const year = moment(date).year();
  const month = moment(date).month() + 1;
  return `${prefix}-${year.toString().slice(2, 4)}${month < 10 ? `${0}${month}` : month
    }-${id}`;
};

export const dateToArray = (date, startHour, endHour) => {
  if (date && startHour && endHour) {
    const [day, month, year] = date.split("/");
    const [hour, minutes] = startHour.split(":");

    return [
      parseInt(year, 10),
      parseInt(month - 1, 10),
      parseInt(day, 10),
      parseInt(hour, 10),
      parseInt(minutes, 10)
    ];
  }
};

export const orderObject = (a, b, key) => {
  if (a[key] < b[key]) return -1;
  else if (a[key] > b[key]) return 1;
  else return 0;
};

export const buildRouters = location => {
  if (location) {
    const routers = location.pathname.split("/");
    const parseRouters = routers
      .map(e =>
        e.length !== 0 && e !== ""
          ? `${e.charAt(0).toLowerCase()}${e.slice(1)}`
          : null
      )
      .filter(e => e);
    return parseRouters;
  }
  return [];
};

export const buildErroHandleItems = (err) => {
  try {
    const { data } = err;
    if (data && data.message) {
      return [
        { type: "danger", message: data.message }
      ];
    }

    if (data && data.errors && Array.isArray(data.errors)) {
      return data.errors.map(error => ({
        type: "danger",
        message: error
      }));
    }
    return [
      { type: "danger", message: "Ocorreu um erro ao realizar operação" }
    ];
  } catch (error) {
    return [
      { type: "danger", message: "Ocorreu um erro ao realizar operação" }
    ];
  }
};


export const minutesToSeconds = time => {
  try {
    const arrayTime = time.split(":");
    const hour = arrayTime[0] * 360;
    const minutes = arrayTime[1] * 60;
    const seconds = arrayTime[2] * 1;
    return hour + minutes + seconds;
  } catch (error) {
    return null;
  }
};

export const secondsToMinutes = seconds => {
  return Math.floor(seconds / 60);
};

const leftPad = (value, totalWidth, paddingChar) => {
  let length = totalWidth - value.toString().length + 1;
  return Array(length).join(paddingChar || "0") + value;
};

export const formatterMaskTime = time => {
  let total = time;
  let hours = Math.floor(total / 3600);
  let minutes = Math.floor((total - hours * 3600) / 60);
  let seconds = Math.floor(total % 60);
  return (
    leftPad(hours, 2) + ":" + leftPad(minutes, 2) + ":" + leftPad(seconds, 2)
  );
};

export const getHoursFromDate = date => {
  return moment(date).format("HH:mm");
};

export const getStageStatusName = status => {
  const finalStatus = StatusStages.filter(e => e.id == status);
  if (finalStatus.length) return finalStatus[0].name;
  else return "";
};

export const buildStartWorkDate = date => {
  if (date) return new Date(date).toLocaleString("pt-BR").replace(",", "");
  return new Date();
};
export const buildStartWorkDateNull = date => {
  if (date) return new Date(date).toLocaleString("pt-BR").replace(",", "");
  return null;
};

export const setHoursInDate = (date, hour) => {
  const hoursAndMinutes = hour.split(":");
  const newDate = buildDate(date);
  newDate.setHours(Number(hoursAndMinutes[0]));
  newDate.setMinutes(Number(hoursAndMinutes[1]));
  return newDate;
};

export const validateSchedules = schedules => {
  try {
    const errors = [];
    schedules.forEach(schedule => {
      if (!schedule.start || schedule.start == "") {
        errors.push("horário inicial da faixa é inválido");
      } else if (!schedule.end || schedule.end == "") {
        errors.push("horário final da faixa é inválido");
      } else if (!schedule.numberOfVacancy) {
        errors.push("número de vagas da faixa é inválido");
      }
    });

    let filtered = errors.filter((e, index) => {
      return errors.indexOf(e) === index;
    });

    return filtered.map(e => ({
      type: "warning",
      message: e
    }));
  } catch (error) {
    return [{ type: "warning", message: "faixa de horário inválida" }];
  }
};

export const sortDescendingArray = (a, b, key) => {
  if (Array.isArray(a[key]) && Array.isArray(b[key])) {
    if (b[key].length - a[key].length) return 1;
    if (a[key].length - b[key].length) return -1;

    return 0;
  }
  if (
    key !== undefined &&
    typeof a[key] === "object" &&
    typeof b[key] === "object"
  ) {
    if (a[key] == undefined && b[key] == undefined) return 0;
    if (b[key].nome.toLowerCase() - a[key].nome.toLowerCase()) return -1;
    if (a[key].nome.toLowerCase() - b[key].nome.toLowerCase()) return 1;
    return 0;
  }
  if (String(b[key]).toLowerCase() - String(a[key]).toLowerCase()) return -1;
  if (String(a[key]).toLowerCase() - String(b[key]).toLowerCase()) return 1;

  return 0;
};

export const sortObjectArray = (a, b, key) => {
  if (Array.isArray(a[key]) && Array.isArray(b[key])) {
    if (a[key].length < b[key].length) return -1;
    if (a[key].length > b[key].length) return 1;
    return 0;
  } else if (typeof a[key] == "object" && typeof b[key] == "object" && a[key] !== null && b[key] !== null) {
    if (a[key].nome.toLowerCase() < b[key].nome.toLowerCase()) return -1;
    if (a[key].nome.toLowerCase() > b[key].nome.toLowerCase()) return 1;
    return 0;
  } else {
    if (String(a[key]).toLowerCase() < String(b[key]).toLowerCase()) return -1;
    if (String(a[key]).toLowerCase() > String(b[key]).toLowerCase()) return 1;
    return 0;
  }
};

export const getFormatMonthArray = values => {
  return values.map(e => {
    if (e > 0 && e <= 13) return months[--e];
    else return months[0];
  });
};

export const getMonthId = values => {
  return values.map(e => {
    return Number(e.value);
  });
};

export const convertTimeOfTest = time => {
  if (!time) {
    return " - ";
  }
  if (time >= 3600) {
    return `${Math.floor(time / 3600)} h`;
  } else {
    return `${secondsToMinutes(time)} min`;
  }
};
export const orderList = (key, order, list) => {

  const parseDate = (dateString) => {
    const [date, time] = dateString.split(" ");
    const [day, month, year] = date.split("/");
    const [hours, minutes] = time.split(":");
    return new Date(year, month - 1, day, hours, minutes);
  };

  const isDate = (dateString) => {
    const regex = /^\d{2}\/\d{2}\/\d{4}\s\d{2}:\d{2}$/;
    return regex.test(dateString);
  };

  const sortedList = list.sort((a, b) => sortObjectArray(a, b, key));

  if (order === undefined || order !== key) {
    sortedList.sort((a, b) => {
      if (!a[key]) return 1;
      if (!b[key]) return -1;

      const valueA = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
      const valueB = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];

      if (valueA === null && valueB !== null) return 1;
      if (valueA !== null && valueB === null) return -1;
      if (valueA === null && valueB === null) return 0;

      if (isDate(valueA) && isDate(valueB)) {
        const dateA = parseDate(valueA);
        const dateB = parseDate(valueB);

        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
        return 0;
      }

      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
      return 0;
    });
    return { list: sortedList, order: key };
  }

  sortedList.sort((a, b) => {
    if (!a[key]) return 1;
    if (!b[key]) return -1;

    const valueA = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
    const valueB = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];

    if (valueA === null && valueB !== null) return -1;
    if (valueA !== null && valueB === null) return 1;
    if (valueA === null && valueB === null) return 0;

    if (isDate(valueA) && isDate(valueB)) {
      const dateA = parseDate(valueA);
      const dateB = parseDate(valueB);

      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    }

    if (valueA > valueB) return -1;
    if (valueA < valueB) return 1;
    return 0;
  });

  return { list: sortedList, order: undefined };
};

export const orderListDescending = (key, order, list) => {

  const sortedList = list.sort((a, b) => sortDescendingArray(a, b, key));
  if (order === undefined || order !== key)
    return { list: sortedList, order: key };
  return { list: sortedList.sort(), order: undefined };
};

export const formatSchedule = value => {
  const time = value.split(":");
  let hours = time[0] ? time[0] : "00";
  let minutes = time[1] ? time[1] : "00";
  let seconds = time[2] ? time[2] : "00";

  if (Number(minutes) > 59) minutes = "59";
  else if (Number(minutes) < 0) minutes = "00";

  if (Number(hours) >= 24) hours = "00";
  else if (Number(hours) < 0) hours = "01";

  if (Number(seconds) > 59) seconds = "59";
  else if (Number(seconds) < 0) seconds = "00";

  return `${hours}:${minutes}:${seconds}`;
};

export const formatErrorsMessages = formErros => {
  return Object.keys(formErros).map(key => {
    return {
      message: `${key} - ${formErros[key][0]}`,
      type: "warning"
    };
  });
};

export const createPaginationObject = (paginacao, info, list, stateList) => {
  try{

    if (!stateList?.length) {

      paginacao.page =
        typeof info.NumeroPagina !== "undefined" ? info.NumeroPagina : 1;

    } else if (list.length !== stateList?.length) {
      paginacao.page = info.NumeroPagina;
    } else if (isEqualObjectArray(list, stateList)) {

      if (info.NumeroPagina > 1) {
        paginacao.page = --info.NumeroPagina;
      } else {
        paginacao.page = info.NumeroPagina;
      }

    } else {
      paginacao.page = info.NumeroPagina;
    }

    paginacao.campo = info?.campo;
    paginacao.value = info?.valor;

    return paginacao;
  }catch(error){
    return null;
  }
};

export const isEqualObjectArray = (list, stateList) => {
  let isEqual = false;
  list.forEach((item, index) => {
    const search = stateList.find((e, i) => item.id == e.id);
    if (search) {
      isEqual = true;
    }
  });
  return isEqual;
};

export const isDateDisabledForEditing = (isEditingAssociation, targetDate) => {
  if (isEditingAssociation) {
    const isBefore = moment(buildDate(targetDate)).isBefore(moment(), "month");
    return isBefore;
  }
  return false;
};
export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
export const formatAssociationAttributes = payload => {
  const idsToAttributes = Object.keys(payload).filter(
    e =>
      e !== "idStage" && e !== "idVacancy" && e !== "idRail" && e !== "stages"
      && e !== "area" && e !== "id" && e !== "job" && e !== "status"  );
  const body = {
    idTrilha: payload.idRail | payload.id,
    podeUtilizarBancoTalentos: payload.useBankOfTalents,
    etapas: idsToAttributes.map(item => ({
      idEtapa: item,
      dataInicial_Realizacao:
        payload[item].periodeRealization &&
        buildDate(payload[item].periodeRealization),
      dataFinal_Realizacao:
        payload[item].testPeriodeRealizationUntil &&
        buildDate(payload[item].testPeriodeRealizationUntil),
      correcaoAutomatica: payload[item].autoCorrect,
      porcentagemParaAprovacao: payload[item].percentAprovation
        ? payload[item].percentAprovation.replace("%", "")
        : undefined,
      dataInicial_Avaliacao:
        payload[item].periodeAvaliation &&
        buildDate(payload[item].periodeAvaliation),
      dataFinal_Avaliacao:
        payload[item].periodeAvaliationUntil &&
        buildDate(payload[item].periodeAvaliationUntil),
      idLocalExame: payload[item].placeOfExame,
      mostrarCrivo: payload[item].showSieveApproval,
      mostrarNota: payload[item].showCandidateScore,
    }))
  };
  return body;
};

export const getJobDescription = (code, list) => {
  if ((code != null && code.id === null) || list.length == 0 || list.find(job => job.id == code)) {
    return "inputs.job";
  }
  return "Cargo: " + code + " inativo";
}

export const colorBoderByStageStatus = status => {
  switch (status) {
    case STATUS_WAITING:
      return theme.colors.whiteDefault.toString();
    case STATUS_ANALYZING:
      return theme.colors.orangeColor.toString();
    case STATUS_DECLINE:
      return theme.colors.errorColor.toString();
    case STATUS_APPROVED:
      return theme.colors.softGreen.toString();
    case STATUS_CANCELED:
      return theme.colors.softGray.toString();
    case STATUS_NOT_INITIALIZE:
      return theme.colors.titleColor.toString();
      case STATUS_CONCLUDED:
        return theme.colors.titleColor.toString();
    default:
      return theme.colors.titleColor.toString();
  }
};

export const colorByStageStatus = status => {
  switch (status) {
    case STATUS_ANALYZING:
      return theme.colors.orangeColor.toString();
    case STATUS_DECLINE:
      return theme.colors.errorColor.toString();
    case STATUS_APPROVED:
      return theme.colors.softGreen.toString();
    case STATUS_CANCELED:
      return theme.colors.softGray.toString();
    case STATUS_NOT_INITIALIZE:
      return theme.colors.white.toString();
    case STATUS_PENDING:
      return theme.colors.white.toString();
    case STATUS_CONCLUDED:
      return theme.colors.titleColor.toString();
    default:
      return theme.colors.titleColor.toString();
  }
};
export const colorTextByStageStatus = status => {
  switch (status) {
    case STATUS_NOT_INITIALIZE:
      return theme.colors.titleColor.toString();
    case STATUS_PENDING:
      return theme.colors.titleColor.toString();
    default:
      return theme.colors.white.toString();
  }
};
